<script>
import _ from 'lodash'

import Card from '@/components/card/Card.vue'

export default {
  props: {
    companyProp: Object,
    isLoadingProp: Boolean
  },
  components: {
    AppCard: Card
  },
  data() {
    return {
      fields: [
        {
          label: 'รหัสผู้ประกอบการ',
          key: 'id',
          col: 12,
          md: 12
        },
        {
          label: 'ชื่อผู้ประกอบการ',
          key: 'name',
          col: 12,
          md: 12
        },
        {
          label: 'ชื่อสถานประกอบการ',
          key: 'branchName',
          col: 12,
          md: 12
        },
        {
          label: 'เลขประจำตัวผู้เสียภาษี',
          key: 'taxNumber',
          col: 12,
          md: 6
        },
        {
          label: 'รหัสสาขา',
          key: 'branch',
          col: 12,
          md: 6
        },
        {
          label: 'อัตราภาษีมูลค่าเพิ่ม (%)',
          key: 'taxRatio',
          type: 'number',
          cols: 12,
          md: 6
        },
        { label: 'อีเมล', key: 'email', col: 12, md: 6 },
        {
          label: 'เลขที่, ถนน',
          key: 'address1',
          col: 12,
          md: 12
        },
        {
          label: 'ตำบล, อำเภอ',
          key: 'address2',
          col: 12,
          md: 12
        },
        { label: 'จังหวัด', key: 'province', col: 12, md: 6 },
        {
          label: 'รหัสไปรษณีย์',
          key: 'postalCode',
          col: 12,
          md: 6
        },
        {
          label: 'โทรศัพท์',
          key: 'telephoneNumber',
          col: 12,
          md: 6
        },
        { label: 'โทรสาร', key: 'faxNumber', col: 12, md: 6 }
      ],
      company: {}
    }
  },
  computed: {
    saveButtonEnable() {
      if (_.isEqual(this.company, this.companyProp)) {
        return false
      }

      for (let field of _.keys(this.company)) {
        const oldValue = this.companyProp[field]
        const newValue = this.company[field]
        if (oldValue !== newValue) return true
      }

      return false
    }
  },
  watch: {
    companyProp() {
      this.reAssignToCompany()
    }
  },
  created() {
    this.reAssignToCompany()
  },
  methods: {
    reAssignToCompany() {
      if (!_.isEqual(this.company, this.companyProp)) {
        this.company = _.cloneDeep(this.companyProp)
      }
    },
    getValue(field) {
      const { key } = field
      if (this.company && this.company[key]) {
        return this.company[key]
      }
      return '-'
    },
    cancelButtonClicked() {
      this.$router.push({ name: 'Basic.BasicInfo' })
    },
    saveButtonClicked() {
      const clonedCompany = {}
      _.keys(this.company).forEach(field => {
        const value = this.company[field]
        if (_.isNull(value)) {
          clonedCompany[field] = null
        } else if (_.isString(value)) {
          if (value.trim().length === 0) {
            clonedCompany[field] = null
          } else {
            clonedCompany[field] = value.trim()
          }
        } else {
          clonedCompany[field] = value
        }
      })
      this.$emit('companyUpdated', clonedCompany)
    }
  }
}
</script>

<template>
  <div class="py-3">
    <app-card>
      <div>
        <b-row>
          <b-col cols="12" class="mb-2">
            <b-row>
              <b-col cols="12" sm="6">
                <div class="d-flex align-items-center h-100">
                  <span class="text-lg font-weight-bold">
                    แก้ไขข้อมูลปูพื้นฐานของกิจการ
                  </span>
                </div>
              </b-col>
              <b-col cols="12" sm="6">
                <div
                  class="d-flex align-items-center justify-content-sm-end mt-2 mt-sm-0"
                >
                  <b-button
                    variant="primary"
                    class="mr-3 px-4"
                    @click="saveButtonClicked()"
                    :disabled="isLoadingProp || !saveButtonEnable"
                  >
                    <b-spinner v-if="isLoadingProp" small></b-spinner>
                    <span v-else>บันทึก</span>
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    class="px-4"
                    @click="cancelButtonClicked()"
                    :disabled="isLoadingProp"
                  >
                    <span>ยกเลิก</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            v-for="field in fields"
            :key="field.key"
            :cols="field.cols"
            :md="field.md"
            class="mt-3"
          >
            <div class="d-flex align-items-center">
              <div class="text-nowrap">
                <span class="mr-2">{{ field.label }} :</span>
              </div>
              <div class="w-100">
                <b-input v-model.trim="company[field.key]"></b-input>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </app-card>
  </div>
</template>

<style scoped></style>
