<script>
import _ from 'lodash'
import { mapState, mapActions } from 'vuex'

import basicInfoService from '@/services/basic/basic-info'

import EditForm from './components/EditForm.vue'

export default {
  components: {
    EditForm
  },
  data() {
    return {
      errMsg: null,
      isLoading: false,
      company: {}
    }
  },
  computed: {
    ...mapState({
      defaultDelayMs: (state) => state.constants.defaultDelayMs
    })
  },
  created() {
    this.fetchCompanyInfo()
  },
  methods: {
    ...mapActions(['fetchOwnInformation']),
    async fetchCompanyInfo() {
      this.isLoading = true
      try {
        const res = await basicInfoService.getCompanyInfo()
        this.company = res.data.data
      } catch (err) {
        this.errMsg =
          err.response?.data?.thMessage ?? 'ดึงข้อมูลบริษัทไม่สำเร็จ'
      }
      this.isLoading = false
    },
    async companyUpdatedHandler(updatedCompany) {
      this.isLoading = true
      try {
        const formatUpdatedCompany = _.omit(updatedCompany, [
          'programEndDate',
          'programStartDate'
        ])
        await basicInfoService.updateCompanyInfo(formatUpdatedCompany)
        await this.fetchOwnInformation()
        setTimeout(() => {
          this.isLoading = false
          this.$router.push({ name: 'Basic.BasicInfo' })
        }, this.defaultDelayMs)
      } catch (err) {
        this.isLoading = false
        this.errMsg =
          err.response?.data?.thMessage ?? 'อัพเดตข้อมูลบริษัทไม่สำเร็จ'
      }
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- menu bar -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="แก้ไขข้อมูลปูพื้นฐานของกิจการ "></page-title>
      </b-col>
    </b-row>

    <!-- edit form -->
    <edit-form
      :companyProp="company"
      :isLoadingProp="isLoading"
      @companyUpdated="companyUpdatedHandler"
    ></edit-form>

    <!-- error modal -->
    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
